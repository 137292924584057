/* NavBar.css */
.navbar {
  background-color: white;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
}

.container {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  justify-content: space-evenly; /* Distribute space evenly between items */
  align-items: center;
  height: 50px;
}

.nav-link {
  color: black;
  text-decoration: none;
  font-weight: 300; /* Use a thin font-weight */
}

.nav-link:hover {
  color: #333; /* Adjust the color on hover */
}
