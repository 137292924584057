/* ProjectsPage.css */

.projects-page-container {
  display: flex;
  flex-direction: column;
  margin: 50px auto 0;
}

.left-projects-pane,
.right-projects-pane {
  width: 100%; /* Full width by default */
  text-align: -webkit-center;
}

.project-bubble {
  width: 85%;
  padding: 15px;
  background-color: white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  cursor: pointer;
  transition: transform 0.3s ease-in-out;
  margin-bottom: 5%;

  img {
    width: 100%;
    border-radius: 10px;
  }

  h3 {
    margin-top: 3%;
  }

  &:hover {
    transform: scale(1.05);
  }
}

@media (min-width: 1001px) {
  .projects-page-container {
    flex-direction: row; /* For screens larger than 1000px, use two panes side by side */
  }

  .left-projects-pane,
  .right-projects-pane {
    width: 50%; /* Each pane takes up 48% of the width */
    
  }

  .projects-page-total-container {
    max-width: 1000px;
    margin: auto;
  }
}

.page-header {
  text-align: center;
  padding: 2.5%;
}

.projects-page-total-container {
  text-align: center;
  display: flex;
  flex-direction: column; /* Adjust for smaller screens */

}
