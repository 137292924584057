/* ProjectModal.css */
.project-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .project-modal {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.3);
    text-align: center;
    max-width: 400px; /* Adjust the max-width as needed */
    width: 100%;
  }

  .project-links a {
    display: block;
    margin-bottom: 10px;
    text-decoration: none;
    color: #3498db; /* or your desired link color */
  }
  
  .close-button {
    background-color: #e74c3c; /* or your desired close button color */
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
    margin-top: 10px;
  }
  