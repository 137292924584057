/* EducationSection.css */

.education-section {
    display: flex;
    justify-content: left;
  }
  
  .education-details {
    display: flex;
    width: 100%;
  }
  
  .education-left {
    flex: 1;
  }
  
  .education-details img {
    max-width:210px;
    object-fit: cover; /* Maintain image aspect ratio while covering the container */
    margin-left: 20px; /* Adjust margin as needed */
  }
  
  h2 {
    margin-right: 20px; /* Adjust margin as needed */
  }
  

  @media (max-width: 1000px) {
    
    .education-details img {
      max-height:210px;
    }
  
  }