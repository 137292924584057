/* General container for the dashboard page */
.dashboard-container {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

/* Page header styles */
.dashboard-header {
  text-align: center;
  margin-bottom: 30px;
}

.dashboard-header h1 {
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 10px;
}

.dashboard-header p {
  font-size: 1rem;
  color: #666;
}

/* Navigation for editor sections */
.dashboard-nav {
  display: flex;
  justify-content: center;
  gap: 20px;
  margin-bottom: 30px;
}

.dashboard-nav a {
  text-decoration: none;
  font-size: 1.2rem;
  color: #007bff;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.dashboard-nav a:hover {
  background-color: #007bff;
  color: white;
}

/* Login container */
.login-container {
  max-width: 400px;
  margin: 100px auto;
  padding: 30px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.login-container h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

.login-container label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.login-container input {
  width: 100%;
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
}

.login-container button {
  width: 100%;
  padding: 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.login-container button:hover {
  background-color: #0056b3;
}

.error-message {
  color: #dc3545;
  font-size: 0.9rem;
  margin-top: 10px;
  text-align: center;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .dashboard-container {
    padding: 15px;
  }

  .dashboard-nav {
    flex-direction: column;
    gap: 10px;
  }

  .dashboard-nav a {
    font-size: 1rem;
    text-align: center;
  }
}

/* Add new blog button */
.add-btn {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 20px;
  padding: 10px 20px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.cancel-btn {
  display: block;
  width: 100%;
  max-width: 200px;
  margin: 0 auto 20px;
  padding: 10px 20px;
  background-color: red;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
}

.add-btn:hover {
  background-color: #218838;
}