/* General container for the blog editor */
.blog-editor {
  max-width: 1000px;
  margin: 20px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  /*background-color: #f9f9f9;*/
  /*border-radius: 10px;*/
  /*box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);*/
}

.blog-editor h3 {
  text-align: center;
  font-size: 2rem;
  color: #333;
  margin-bottom: 20px;
}

/* List of blogs */
.editor-item-list {
  margin-top: 20px;
  padding: 0;
  list-style: none;
}

.editor-item {
  display: flex;
  justify-content: space-between; /* Ensure items are spaced across the row */
  align-items: center; /* Align items vertically */
  padding: 10px;
  border-bottom: 1px solid #ddd;
  flex-wrap: nowrap; /* Prevent row from wrapping */
}

.editor-item::before {
  content: '';
}

.editor-item h4 {
  margin: 0;
  font-size: 1.2rem;
  color: #333;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 80%; /* Fixed width for consistency */
  max-width: 80%; /* Ensure it doesn't expand beyond this */
  height: 1.4em; /* Fixed height to ensure consistent size */
  line-height: 1.4em; /* Align text vertically within the height */
}

.editor-item-buttons {
  display: flex;
  gap: 10px; /* Add space between buttons */
}

.editor-item-buttons button {
  background-color: #007bff;
  border: none;
  padding: 8px 12px;
  border-radius: 5px;
  color: white;
  font-size: 0.9rem;
  cursor: pointer;
  transition: background-color 0.3s ease;
  text-align: center;
  min-width: 80px; /* Ensure buttons have consistent size */
}
/* Modal styles */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000; /* Ensure the modal is above all other content */
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.3);
  width: 100%;
  max-width: 500px;
  box-sizing: border-box;
}

.modal-content h3 {
  margin-top: 0;
  color: #333;
  font-size: 1.5rem;
  text-align: center;
}

.modal-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #333;
}

.modal-content input,
.modal-content textarea {
  width: calc(100% - 20px); /* Adjust for padding */
  padding: 10px;
  margin-bottom: 15px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  box-sizing: border-box;
}

.modal-content textarea {
  resize: vertical;
  min-height: 80px; /* Reduce the height to fit better within the modal */
}

/* Style for the "Choose File" button */
.modal-content input[type="file"] {
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
  box-sizing: border-box;
  background-color: #f9f9f9;
}

.modal-content input[type="file"]:hover {
  background-color: #e9e9e9;
}

/* Buttons in the modal */
.modal-content .editor-buttons {
  display: flex;
  justify-content: space-between; /* Spread buttons across the modal */
  gap: 10px;
}


.modal-content .editor-buttons button:hover {
  background-color: #004b9a;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .modal-content {
    padding: 15px;
    width: 90%; /* Make the modal narrower on smaller screens */
  }

  .modal-content input,
  .modal-content textarea {
    font-size: 0.9rem; /* Reduce input font size on small screens */
  }

  .modal-content .editor-buttons button {
    font-size: 0.9rem; /* Adjust button font size */
    padding: 8px;
  }
}