/* FilterDropdown.css */
.filter-dropdown {
  display: inline-block;
  position: relative;
}

.filter-button {
  padding: 10px;
  background-color: #3498db; /* or your desired button color */
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.tag-container span {
  display: inline-block;
  margin: 5px;
  padding: 5px;
  border: 1px solid #3498db; /* or your desired border color */
  border-radius: 5px;
  cursor: pointer;
}

.tag-container span.selected {
  background-color: #3498db; /* or your desired selected tag color */
  color: white;
}

.tag-container span:hover {
  background-color: #3498db; /* or your desired hover color */
  color: white;
}

.filter-dropdown:hover .tag-container {
  max-height: 300px; /* Adjust the max height as needed */
}
