/* HomePage.css */

/* General container for the homepage */
.homepage-container {
  text-align: center;
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-height: 100vh; /* Set min-height to 100% of viewport height */
  margin: 0 auto; /* Center the content horizontally */
  max-width: 2000px; /* Set your desired max width */
  padding: 0 20px; /* Optional padding for content inside the max-width */
  box-sizing: border-box; /* Include padding and border in the total width and height */
}

.mobile-img {
  display: none;
}

.header-divider {
  height: 3px;
  background-color: #fff;
  width: 100%;
  margin-top: 1%;
}

.header-divider-light {
  height: 3px;
  margin-top: 1%;
  background-color: #eee;
  width: 100%;
}

.left-container {
  text-align: left;
  max-width: 1000px;
  padding-top: 5%;
}

.custom-link {
  text-decoration: none;
  color: inherit; 
}

/* Styles for the content-container */
.content-container {
  margin: 20px auto;
  width: 100%;
  max-width: 1000px;
}

.landing-homepage {
  position: relative; /* Set position to relative for positioning children */
  width: 100%;
  height: 100vh;
  font-family: 'ADAM.CG PRO';
  background-color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  color: #fff;
  overflow: hidden;
  z-index: 0; /* Ensure it stacks correctly with children */
}

/* Text styles */
.top-line {
  font-size: 6em;
  padding-top: 2%;
  margin-bottom: 7.5px;
  z-index: 1; /* Keep the text on top */
}

.second-line {
  font-size: 6em;
  z-index: 1; /* Keep the text on top */
}

/* Style the image */
.landing-main-image {
  position: absolute; /* Set position to absolute for overlay */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Maintain image aspect ratio while covering the container */
  z-index: -1; /* Ensure the image is behind the text */
}

/* Style lower left container */
.left-lower-tag {
  position: absolute;
  font-size: 2rem;
  bottom: 12%;
  left: 5%;
  width: 25%;
  padding: 20px;
  border-radius: 8px;
  text-align: left;
  z-index: 1; /* Keep above the image */
}

/* Style lower right container */
.right-lower-tag {
  position: absolute;
  bottom: 10%;
  font-size: 2rem;
  right: 5%;
  width: 20%;
  padding: 20px;
  border-radius: 8px;
  text-align: right;
  z-index: 1; /* Keep above the image */
}

.footer-link {
  margin-right: 3%; /* Adjust the spacing as needed */
}

/* Responsive adjustments */
@media (max-width: 1395px) {
  .top-line {
    font-size: 6em;
  }
  
  .second-line {
    font-size: 6em;
  }
}

@media (max-width: 1375px) {
  .landing-main-image {
    display: none; /* Hide the image for smaller screens */
  }

  .top-line {
    font-size: 4em;
    color: black;
  }
  
  .second-line {
    font-size: 4em;
    color: black;
  }

  .left-lower-tag {
    font-size: 1rem;
    color: black;
  }
  
  .right-lower-tag {
    font-size: 1rem;
    color: black;
  }
}

@media (max-width: 850px) {
  .landing-main-image {
    display: none; /* Hide the image for very small screens */
  }

  .top-line {
    font-size: 2em;
    color: black;
  }
  
  .second-line {
    font-size: 2em;
    color: black;
  }

  .left-lower-tag {
    font-size: 1rem;
    width: 50%;
    color: black;
  }
  
  .right-lower-tag {
    font-size: 1rem;
    width: 50%;
    color: black;
  }

  .mobile-img {
    display: block; /* Display the mobile image alternative */
  }
}