/* styles.css */
@import './index.css';


.home-page,
.projects-page,
.blog-page {
  text-align: center;
  padding: 20%;
}


ul {
  list-style-type: none;
}
