/* General container for the books page */
.books-page-container {
    max-width: 1000px; /* Set a comfortable max-width for readability */
    margin: 0 auto; /* Center the container horizontally */
    padding: 20px;
    font-family: Arial, sans-serif;
}

/* Page header styles */
.page-header {
    text-align: center;
    margin-bottom: 30px;
}

.page-title {
    font-size: 2.5rem;
    margin: 0;
    color: #333;
}

.page-description {
    font-size: 1rem;
    color: #666;
    margin-top: 10px;
}

/* Books grid */
.books-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(200px, 1fr)); /* Increase min width for larger boxes */
    gap: 20px; /* Space between grid items */
    margin-top: 20px;
}

/* Individual book item */
.book-item {
    cursor: pointer;
    padding: 12px; /* Increase padding for a larger box */
    margin: 8px 0;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f5f5f5;
    display: flex;
    flex-direction: column;
    align-items: center;
    min-height: 250px; /* Set a minimum height to accommodate all content */
}
  
.book-item:hover {
    transform: scale(1.05); /* Slight zoom effect */
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1); /* Add a subtle shadow */
}
  
/* Book image */
.book-image {
    width: 120px; /* Increase the image size */
    height: 180px;
    margin: 0 auto 10px;
    object-fit: cover;
    border-radius: 3px;
}

/* Book title */
.book-title {
    width: 220px; /* Slightly wider title box */
    white-space: nowrap; /* Prevent text wrapping */
    overflow: hidden; /* Hide overflowing text */
    text-overflow: ellipsis; /* Show ellipsis for truncated text */
    text-align: center; /* Center the title text */
    font-size: 18px; /* Adjust font size as needed */
    margin: 8px 0;
}
  
/* Book author */
.book-author {
    font-size: 14px;
    margin: 4px 0;
    text-align: center; /* Center-align the author text */
}

/* Rating stars */
.rating-stars {
    display: flex;
    justify-content: center; /* Center-align the stars */
    margin-top: 4px;
}

/* Responsive adjustments */
@media (max-width: 768px) {
    .page-title {
        font-size: 2rem;
    }

    .book-title {
        font-size: 0.9rem;
    }

    .book-author {
        font-size: 0.8rem;
    }

    .rating-star {
        font-size: 0.9rem;
    }
}