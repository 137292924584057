/* General container for the blog page */
.blog-container {
  max-width: 800px; /* Set a comfortable width for readability */
  margin: 0 auto; /* Center the content horizontally */
  padding: 20px;
  font-family: Arial, sans-serif;
  list-style: none;
}

/* Header section */
.page-header {
  text-align: center;
  margin-bottom: 30px;
}

.page-title {
  font-size: 2.5rem;
  margin: 0;
  color: #333;
}

.page-description {
  font-size: 1rem;
  color: #666;
  margin-top: 10px;
}

.blog-title-item::before {
  content: '';
}

/* Blog list container */
.blog-list-container {
  display: flex;
  justify-content: center; /* Center the list horizontally */
  margin-top: 20px;
}

.blog-titles-list {
  list-style: none; /* Completely remove list markers */
  padding: 0;
  margin: 0;
  width: 100%; /* Make the list take up the full container width */
}

/* Individual blog title items */
.blog-title-item {
  margin: 10px 0; /* Add space between items */
}

.blog-title-link {
  font-size: 1.2rem;
  text-decoration: none; /* Remove underline */
  color: #0073e6; /* Use a distinct color for links */
  transition: color 0.3s ease; /* Add a smooth transition for hover effect */
}

.blog-title-link:hover {
  color: #005bb5; /* Darken the color on hover */
  text-decoration: underline; /* Underline the link on hover */
}

/* Responsive adjustments */
@media (max-width: 600px) {
  .blog-container {
    padding: 15px;
  }

  .page-title {
    font-size: 2rem;
  }

  .blog-title-link {
    font-size: 1rem;
  }
}