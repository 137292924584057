/* Contact.css */

.contact-container {
  max-width: 1000px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding: 20px;
}

.about-me {
  width: 50%; /* Adjusted width to allow more space for contact details */
}

.profile-container {
  width: 40%; /* Adjusted width to allow more space for contact details */
}

.profile-picture {
  width: 100%;
  max-width: 600px;
}

.contact-details {
  width: 100%;
  margin-top: 2%;
  display: flex;
  flex-direction: column;
  flex-grow: 1; /* Allow the contact details to grow and take remaining space */
}

.contact-item {
  display: flex;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 10px;
  justify-content: center;
  cursor: pointer;
  transition: transform 0.3s;
}

.contact-item:hover {
  transform: scale(1.05);
}

.icon {
  width: 20px;
  margin-right: 10px;
}

.email {
  background-color: #222;
  color: white;
}

.phone {
  background-color: #555;
  color: white;
}

.linkedin {
  background-color: #0077b5;
  color: white;
}

.github {
  background-color: #282c34;
  color: white;
}

.leet {
  background-color: #ffa116;
  color: white;
}

.strava {
  background-color: #fc4c02;
  color: white;
}

.spotify {
  background-color: #1DB954;
  color: white;
}

.copy-notification {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 10px;
  background-color: #4CAF50;
  color: white;
  border-radius: 5px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.3s, visibility 0.3s;
}

.show-notification {
  opacity: 1;
  visibility: visible;
}

@media (max-width: 1000px) {
  .profile-container {
    display: none;
  }

  .contact-container {
    max-width: 100%;
    margin: 0 auto;
    padding: 20px;
  }

  .about-me {
    width: 100%;
  }
}

.subscribe {
  background-color: #007bff;
  color: white;
}

.subscribe:hover {
  background-color: #0056b3;
}

.subscribe-prompt {
  margin-top: 15px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
}

.subscribe-prompt input {
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 1rem;
}

.subscribe-prompt button {
  padding: 10px 20px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 1rem;
  cursor: pointer;
}

.subscribe-prompt button:hover {
  background-color: #0056b3;
}

.subscribe-message {
  margin-top: 10px;
  font-size: 0.9rem;
  color: #333;
}