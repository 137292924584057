/* BlogPost.css */

.blog-post-container,
.blog-post-container p {
  font-family: 'Newsreader';
  font-size: 1.25rem;
}

.blog-post-container img {
  margin: 0 auto; 
  display: block;
}

.blog-post {
  margin-bottom: 20px;
  width: 100%;
}

.snippet {
  color: #555;
  margin-bottom: 15px;
  width: 100%;
  font-family: 'Newsreader';
}

.thumbnail {
  width: 100%;
  max-height: 200px;
  object-fit: cover;
  margin-bottom: 15px;
}

.thumbnail img {
  display: block;
  margin: 0 auto; /* Center images horizontally */
}

.divider {
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
}

/* BlogPost.css */
.blog-post-container {
  display: flex;
  justify-content: center;
}

.blog-post-content {
  max-width: 800px; /* Adjust the max-width to your preference */
  width: 100%;
  padding: 20px;
}

.published-date {
  font-family: 'Bebas Neue', sans-serif; /* Choose a special font for the date */
  font-size: 1rem; /* Adjust the font size to your preference */
  font-weight: bold;
  color: #333;
  margin-bottom: 10px;
}

/* Add any additional styling as needed */
